<script>
	import { closeModal } from 'svelte-modals';
	import { afterNavigate } from '$app/navigation';

	/**
	 * @type {any}
	 */
	export let component;
	/**
	 * @type {any}
	 */
	export let props;
	/**
	 * @type {any}
	 */
	export let isOpen;

	afterNavigate((navigate) => {
		closeModal();
	});
	/**
	 * @param {{ key: string; }} event
	 */
	function HandleKeypress(event) {
		if (event.key === 'Escape') {
			closeModal();
		}
	}
</script>

{#if isOpen}
	<!-- svelte-ignore a11y-no-noninteractive-tabindex -->
	<!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
	<div role="dialog" class="modal" on:keydown={HandleKeypress}>
		<div class="contents">
			<svelte:component this={component} {props} on:click />
		</div>
	</div>
{/if}

<style>
	.modal {
		position: fixed;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		display: flex;
		justify-content: center;
		align-items: center;

		pointer-events: none;
		z-index: 2;
	}

	.contents {
		min-width: 240px;
		border-radius: 6px;
		padding: 16px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		pointer-events: auto;
		max-height: 100vh;
	}
</style>
